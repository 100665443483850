import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Karriereportal-Widget",
  "description": "So binden Sie Stellenanzeigen auf Ihrer Website ein",
  "author": "Anna-Mai Petersen",
  "categories": ["settings"],
  "date": "2021-07-30T00:00:00.000Z",
  "featured": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Vereinfachen Sie sich den Arbeitsalltag und langwierige HR-Prozesse: integrieren Sie das inriva- Karriereportal Widget in Ihren Arbeitsalltag. Es lässt sich leicht durch einfügen einiger kurzer Codezeilen, wie in oben gezeigter Grafik, auf Ihrer Website integrieren. Sie erhalten von unseren Produktentwicklern einen auf Sie abgestimmten Code. Dieser Code kann durch Sie, Ihren Webadministrator und einer von uns zur Verfügung gestellten, qualifizierten Person in Ihr vorhandenes System implementiert werden. -Durch eigens von uns konzipierte Leitfäden werden Sie Schritt für Schritt beim Einfügen des Codes begleitet. Diese Gebrauchsanweisungen sind für alle gängigen Content-Management Systeme wie TYPO3, WordPress und viele mehr verfügbar. Durch eben dieses Hinführen an das Einbauen des Codes wird vor allem eine schnelle und leichte Einbindung des inriva-Karriereportal Widget sichergestellt – egal wann, egal wo.`}</p>
    <p>{`Ein kleiner Tipp: Sollten Sie eine CI-angepasste Version wünschen, so können Sie uns gerne auf weitere Details wie Farbgebung, Layout etc. ansprechen. Wir erfüllen seit Beginn an gerne Ihre Wünsche!`}</p>
    <p>{`Einfachheit und Klarheit sind starke Vorteile des inriva-Karriereportal Widget. Dem Bewerber ist es möglich sich intuitiv auf der Karriereseite zu bewegen, sich ausgiebig zu informieren. Folglich fällt es ihm leicht den nächsten Schritt zu gehen und er bewirbt sich. Schnell und unkompliziert für beide Parteien.`}</p>
    <img src="/images/settings/widget.jpg" alt="Jobboersen" style={{
      "width": "100%"
    }} />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      